import React from "react";
import Navigate from "../navigate";

function TnC(props) {
    return <div className="tnc" data-datocms-noindex>
        <Navigate href={'/website-terms-of-use'}>Terms of Use</Navigate>
        <Navigate href={'/website-privacy-policy'}>Privacy Policy</Navigate>
    </div>;
}

export default TnC;