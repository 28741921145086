import React, {useEffect, useState} from 'react'
import {graphql, StaticQuery} from "gatsby";
import Copyright from "./copyright";
import FooterHeader from "./footerHeader";
import TnC from "./tnc";
import {URL} from "../../constants/urls";
import {STRINGS} from "../../constants/strings";
import {isMobile} from "react-device-detect";
import SubscribePopup from "../subscribe/SubscribePopup";
import Navigate from "../navigate";

const renderNavItems = (isMobile = false, props) => (
    <StaticQuery
        query={graphql`
            query FooterCategoryQuery {
              datoCMS {
                allCategories(filter: {parent: {exists: "false"}, slug: {neq: "news"}}, orderBy: position_ASC) {
                  slug
                  title
                  children {
                    slug
                    title
                  }
                }
              }
            }
        `}
        render={data => {
            if(isMobile) {
                return <div className={'categories'}  data-datocms-noindex>
                    <div className={'first-row'}>
                        <Navigate href={"/news-category"} className={'news-category' === props.categoryPath ? 'active' : ''}>{STRINGS.LATEST_NEWS}</Navigate>
                        <span>|</span>
                        {data.datoCMS.allCategories.slice(0, 2).map((category, index) => {
                            return <><Navigate href={`/${category.slug}`} className={category.slug === props.categoryPath ? 'active' : ''} key={category.slug}>{category.title}</Navigate>{index !== 1 && <span>|</span>}</>
                        })}
                    </div>
                    <div className={'second-row'}>
                        {data.datoCMS.allCategories.slice(2, 4).map((category, index) => {
                            return <><Navigate href={`/${category.slug}`} className={category.slug === props.categoryPath ? 'active' : ''} key={category.slug}>{category.title}</Navigate>{index !== 1 && <span>|</span>}</>
                        })}
                    </div>
                </div>
            } else {
                return data.datoCMS.allCategories.map(category => {
                    return <div key={category.slug}>
                        <div className="category-nav__item">
                            {category.title}
                        </div>
                        <hr className="divider"/>
                        {
                            category.children.map(subCategory =>
                                <Navigate className="sub-nav__item"
                                      key={subCategory.slug}
                                      href={`/${category.slug}/${subCategory.slug}`}
                                      style={subCategory.slug === props.subCategoryPath ? {fontWeight: 'bold'} : {}}>
                                    {subCategory.title}
                                </Navigate>
                            )
                        }
                    </div>
                })
            }
            }
        }
    />
)

const Footer = (props) => {
    const [isMobileFooter, setIsMobileFooter] = useState(false);
    const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
    useEffect(() => {
        setIsMobileFooter(isMobile);
    }, [isMobile])

    return (
        <footer className={`page-foot ${isMobileFooter ? 'mobile' : ''}`} role="contentinfo" data-datocms-noindex>
            <FooterHeader subscribeText={STRINGS.SUBSCRIBE_NOW} subscribeClass={'footer-button'}/>
            <div className="nav-section">
                <nav className="page-foot__wrapper">
                    {!isMobileFooter && renderNavItems(false, props)}
                    <div className={`right-section`}>
                        {isMobileFooter && <>
                            <div className="subscribe-section" onClick={() => {
                                setIsSubscribeOpen(true)
                            }}>
                                <div>
                                    <a dangerouslySetInnerHTML={{__html: STRINGS.SUBSCRIBE}}>
                                    </a>
                                </div>
                            </div>
                            {renderNavItems(true, props)}
                            <hr className="divider"/>
                        </>}
                        {!isMobileFooter && <>
                            <div className="category-nav__item">
                                <Navigate href="/about-us">About us</Navigate>
                            </div>
                            <hr className="divider"/>
                        </>}
                        <div className={"contact-us-section"}>
                            <div className="category-nav__item">
                                <Navigate href="/advertise-with-us">Advertise with us</Navigate>
                            </div>
                            {!isMobileFooter && <hr className="divider"/>}
                        </div>

                        <div className={"contact-us-section"}>
                            <div className="category-nav__item">
                                Contact us
                            </div>
                            <div className="sub-nav__item">
                                <a target="_blank" href={`mailto:${URL.INFO_EMAIL}`}>{URL.INFO_EMAIL}</a>
                            </div>
                            <div className="sub-nav__item address" dangerouslySetInnerHTML={{__html: STRINGS.ADDRESS}}>
                            </div>
                        </div>
                    </div>
                </nav>
                <TnC/>
            </div>
            <Copyright/>
            {isSubscribeOpen && <SubscribePopup onPopupClose={() => {
                setIsSubscribeOpen(false)
            }}/>}
        </footer>
    )
}

export default Footer
